module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicola Squartini’s Blog","short_name":"tensor5","start_url":"/","background_color":"#fafafa","theme_color":"#1976d2","display":"minimal-ui","icons":[{"src":"manifest-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"manifest-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"manifest-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"manifest-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"manifest-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"manifest-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"manifest-icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"manifest-icon-36x36-maskable.png","sizes":"36x36","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-48x48-maskable.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-72x72-maskable.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-96x96-maskable.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-144x144-maskable.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-192x192-maskable.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"manifest-icon-512x512-maskable.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"include_favicon":false,"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
