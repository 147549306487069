import { responsiveFontSizes } from "@mui/material"
import { createTheme } from "@mui/material/styles"

import blue from "@mui/material/colors/blue"

export default responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      background: {
        default: "#fafafa",
      },
      primary: {
        light: blue[500],
        main: blue[700],
        dark: blue[900],
      },
      secondary: {
        main: "#d27619",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: "large",
        },
      },
      MuiLink: {
        defaultProps: {
          color: "secondary",
          underline: "hover",
        },
      },
    },
  }),
)
